<template lang="pug">
  .option-form
    ArrowBack(@back="handleClickBack")
    .title
      span {{ $t("company_system.basic_settings.options.options_form.details") }}
    .form
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.options.options_form.code')")
        .form-row-field
          BFormInput(
            type="text"
            name="code"
            v-model="option.code"
            :placeholder="$t('company_system.basic_settings.options.options_form.code')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.options.options_form.name')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          BFormInput(
            type="text"
            :class="{ invalid: $v.option.name.$error }"
            name="name"
            v-model="option.name"
            :placeholder="$t('company_system.basic_settings.options.options_form.name')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.options.options_form.tax')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          .taxes.flex-row
            .taxes-radio.flex-row
              AppRadioButton(
                :value="!option.taxable"
                @change="switchTaxFree"
              )
              .label
                span {{ $t(`company_system.basic_settings.options.options_form.taxation`) }}
            .taxes-radio.flex-row
              AppRadioButton(
                :value="option.taxable"
                @change="switchTaxFree"
              )
              .label
                span {{ $t(`company_system.basic_settings.options.options_form.tax_exempt`) }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.options.options_form.fee_type')")
          span.required {{ $t('company_system.req') }}
          AppTooltip(
            :title="$t('company_system.basic_settings.options.options_form.fee_type_required')"
            icon="info-circle"
          )
        .form-row-field.fee-types
          AppDropdown(
            close-on-select
            allow-empty
            :class="{ invalid: $v.option.fee_type.$error }"
            value-key="type"
            title-key="name"
            :value="selectedFeeType"
            :items="feeTypes"
            @select="selectFeeType"
          )
        template(v-if="option.fee_type === fixedFeeType")
          .form-row-field.fee.flex-row
            AppNumberInput(
              use-delimiter
              :class="{ invalid: $v.option.fee_data.price.$error }"
              name="fee-value"
              :max="999999"
              :min="0"
              v-model="option.fee_data.price"
            )
            .fee-label
              span {{ $t('company_system.basic_settings.options.options_form.yen_rental') }}
        template(v-else-if="option.fee_type === periodFeeType")
          .flex-row(v-for="(hoursBeforeItem, index) in $v.option.fee_data.hours_before.$each.$iter")
            .form-row-field.flex-row.hour
              AppNumberInput(
                name="hours"
                :class="{ invalid: isHoursInoutInvalid(hoursBeforeItem.hours) }"
                :min="0"
                v-model="hoursBeforeItem.$model.hours"
              )
              .fee-label
                span {{ $t('company_system.basic_settings.options.options_form.until_hour') }}
            .form-row-field.flex-row.fee
              AppNumberInput(
                use-delimiter
                :class="{ invalid: hoursBeforeItem.price.$error }"
                name="price"
                :max="999999"
                :min="0"
                v-model="hoursBeforeItem.$model.price"
              )
              .fee-label
                span {{ $t('general_settings.yen') }}
            .form-row-field.action-column
              AppIconButton.action-button(
                v-if="showRemoveButton(Number(index))"
                icon="plus-circle"
                @click="removeLastHoursBeforeItem()"
              )
          .form-row-field
            AppIconButton(
              icon="plus-circle"
              title="company_system.basic_settings.options.options_form.add_time_segment"
              @click="addHoursBeforeItem"
            )
        template(v-else-if="option.fee_type === dayFeeType")
          .form-row-field.flex-row.fee
            AppNumberInput(
              use-delimiter
              :class="{ invalid: $v.option.fee_data.price.$error }"
              name="fee-value"
              :max="999999"
              :min="0"
              v-model="option.fee_data.price"
            )
            .fee-label
              span {{ $t('company_system.basic_settings.options.options_form.yen_day') }}
          .form-row-field.fee.flex-row
            .flex-row
              .label
                span {{ $t(`company_system.basic_settings.options.options_form.fee_cap`) }}
              AppRadioButton.fee-cap-radio(
                :value="option.fee_data.use_limit"
                @change="switchUseLimit"
              )
              .label
                span {{ $t(`company_system.basic_settings.options.options_form.set_fee_cap`) }}
              AppRadioButton.fee-cap-radio(
                :value="!option.fee_data.use_limit"
                @change="switchUseLimit"
              )
              .label
                span {{ $t(`company_system.basic_settings.options.options_form.dont_set_fee_cap`) }}
          .form-row-field.flex-row.fee
            AppNumberInput(
              :disabled="!option.fee_data.use_limit"
              use-delimiter
              :class="{ invalid: $v.option.fee_data.days_upper_limit.$error }"
              name="fee-cap"
              :max="999999"
              :min="0"
              v-model="option.fee_data.days_upper_limit"
            )
            .fee-label
              span {{ $t('company_system.basic_settings.options.options_form.yen_cap') }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.options.options_form.used_pieces_number')")
        .form-row-field.used-pieces-number.flex-row
          AppCheckbox(
            v-model="option.multiple_rentals"
          )
          .label
            span {{ $t('company_system.basic_settings.options.options_form.multiple_rentals_possible') }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.options.options_form.explanation')")
        .form-row-field
          textarea(
            :rows="3"
            name="explanation"
            v-model="option.description"
            :class="{ 'invalid': $v.option.description.$error }"
            :placeholder="$t('company_system.basic_settings.options.options_form.explanation')"
          )
          .text-length(
            :class="{ 'invalid': !$v.option.description.maxLength }"
          )
            | {{ explanationLengthLabel }}
      FormTable(
        :selected-shop-ids="option.companies_shop_ids"
        @select-all="selectAllShops"
        @clear-all="clearAllShops"
        @select-shop="handleShopSelect"
      )
    FormActionBar(
      :delete-tooltip="deleteTooltip"
      :delete-enabled="isEdit && !option.unremovable"
      @delete="removeOption"
      @save="handleSave"
    )
</template>

<script>
  // misc
  import { find, cloneDeep, isEqual } from "lodash-es"
  import { optionValidator } from "./validator"
  import { FIXED_FEE_TYPE, PERIOD_FEE_TYPE, DAY_FEE_TYPE, EXPLANATION_CAPTION_MAX_LENGTH, feeTypes } from "./constants"
  import { defaultOptionObject, buildFeeObject, defaultHoursBeforeItem } from "./helpers"

  // store modules
  import optionsModule from "@/config/store/company_system/basic_settings/options"

  // mixins
  import { validationMixin } from "vuelidate"
  import withStoreModule from "@/mixins/withStoreModule"
  import withScrollTop from "@/mixins/withScrollTop"
  import withCompanySystemFormMethods from "@/mixins/company_system/withFormMethods"

  const optionsMixin = withStoreModule(optionsModule, {
    name: "optionsMixin",
    readers: { optionItem: "item" },
    actions: {
      fetchOption: "FETCH_ITEM",
      createOption: "CREATE_ITEM",
      updateOption: "UPDATE_ITEM",
      deleteOption: "DELETE_ITEM"
    },
    mutations: { setDirty: "SET_DIRTY" }
  })

  export default {
    components: {
      ArrowBack: () => import("@/components/CompanySystem/ArrowBack"),
      FormActionBar: () => import("../../FormActionBar"),
      FormTable: () => import("./FormTable"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    mixins: [optionsMixin, withScrollTop, withCompanySystemFormMethods, validationMixin],

    data() {
      return {
        option: defaultOptionObject(),
        initialOption: {},
        fixedFeeType: FIXED_FEE_TYPE,
        periodFeeType: PERIOD_FEE_TYPE,
        dayFeeType: DAY_FEE_TYPE
      }
    },

    validations() {
      return {
        option: optionValidator(this.option.fee_type)
      }
    },

    props: {
      optionId: {
        type: Number,
        required: false
      }
    },

    mounted() {
      if (this.isEdit) {
        this.fetchOption(this.optionId).then(() => {
          this.option = cloneDeep(this.optionItem)
          this.initialOption = cloneDeep(this.optionItem)
        })
      } else {
        this.initialOption = defaultOptionObject()
      }
    },

    methods: {
      adjustFeeObject() {
        this.option.fee_data = { ...this.option.fee_data, ...buildFeeObject(this.option.fee_type) }
      },

      showRemoveButton(index) {
        return index > 0 && this.option.fee_data.hours_before.length === index + 1
      },

      addHoursBeforeItem() {
        this.option.fee_data.hours_before.push(defaultHoursBeforeItem())
      },

      removeLastHoursBeforeItem() {
        this.option.fee_data.hours_before.pop()
      },

      validateAttributes() {
        this.$v.$touch()
      },

      cancelValidation() {
        this.$v.$reset()
      },

      isHoursInoutInvalid(hours) {
        return hours.$error || (!this.$v.option.fee_data.hoursMustBeInAscOrder && this.$v.option.fee_data.$error)
      },

      selectAllShops(shopIds) {
        this.option.companies_shop_ids = shopIds
      },

      clearAllShops() {
        this.option.companies_shop_ids = []
      },

      handleShopSelect(shopId) {
        if (this.option.companies_shop_ids.includes(shopId)) {
          const index = this.option.companies_shop_ids.indexOf(shopId)
          this.option.companies_shop_ids.splice(index, 1)
        } else {
          this.option.companies_shop_ids.push(shopId)
        }
      },

      textLength(length, maxLength) {
        return `(${length}/${maxLength})`
      },

      switchUseLimit() {
        this.option.fee_data.use_limit = !this.option.fee_data.use_limit
      },

      switchTaxFree() {
        this.option.taxable = !this.option.taxable
      },

      selectFeeType({ type }) {
        this.option.fee_type = type
        this.adjustFeeObject()
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.option = cloneDeep(this.optionItem)
          this.initialOption = cloneDeep(this.optionItem)
        })
      },

      async sendRequest() {
        if (this.isEdit) {
          await this.updateOption(this.option)
        } else {
          await this.createOption(this.option)
        }
      },

      removeOption() {
        this.$confirm({
          title: this.$t("company_system.basic_settings.delete_option_confirmation"),
          resolve: {
            handler: () => {
              this.deleteOption(this.option.id).then(() => {
                this.$emit("back")
              })
            }
          }
        })
      }
    },

    computed: {
      feeTypes,

      isValid() {
        return !this.$v.$invalid
      },

      deleteTooltip() {
        return this.option.unremovable ? this.$t("company_system.relation_tooltips.has_plans") : undefined
      },

      hasUnsavedChanges() {
        return !isEqual(this.option, this.initialOption)
      },

      isEdit() {
        return !!(this.optionId || this.option.id)
      },

      selectedFeeType() {
        return find(this.feeTypes, { type: this.option.fee_type })
      },

      explanationLength() {
        return this.option.description?.length || 0
      },

      explanationLengthLabel({ explanationLength }) {
        return this.textLength(explanationLength, EXPLANATION_CAPTION_MAX_LENGTH)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"

  .option-form
    +styled-inputs
    input
      height: 38px

    margin-bottom: 20px

    .flex-row
      display: flex
      justify-content: left

    .title
      margin-top: 20px
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          .app-tooltip
            ::v-deep
              svg
                vertical-align: text-bottom
                width: 17px
                height: 17px
                path
                  fill: $default-purple

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          .fee-label
            padding: 7px
            font-size: 15px

          .fee-cap-radio
            margin: 2px 7px

          &.action-column
            margin-top: 10px

            .action-button
              transform: rotate(45deg)
              +icon-button($default-purple)

          ::v-deep
            .app-number-input
              &.invalid
                input
                  +default-invalid-input

            input
              height: 38px
              &.invalid
                +default-invalid-input

          .text-length
            line-height: 1.0
            font-size: 12px
            color: $default-gray-medium

            &.invalid
              color: $default-red

          &.shop-destination
            &-header
              justify-content: space-between

              .button-bar
                .label
                  padding: 7px
                  font-size: 15px
                  color: $default-gray-medium
                button
                  border: 1px solid $default-gray-medium
                  margin-right: 10px
                  padding: 5px

          &.used-pieces-number
            .app-checkbox
              padding: 0

          &.fee-types
            .app-select
              padding: 0

          &.hour
            ::v-deep
              input
                width: 40px

          &.fee
            ::v-deep
              input
                text-align: right
                width: 90px

          textarea
            width: 100%
            border: 1px solid $default-purple-light
            border-radius: 5px
            padding-left: 5px

            &.invalid
              +default-invalid-input

        .taxes
          &-radio
            font-weight: 300
            margin-right: 10px

            .app-radio-button
              padding-top: 2px

            .label
              margin-left: 5px
</style>
